/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import * as styles from './ContactForm.module.scss';
import { Button, TextField } from '@vccp/bernadette-components';
import { useForm } from 'react-hook-form';
import { BernyDialog } from '@vccp/bernadette-components';
import { RadioButton } from '@vccp/bernadette-components';
import { CheckBox } from '@vccp/bernadette-components';
import ParallaxWithBgr from '../ParallaxWithBgr/ParallaxWithBgr';
import FormBgr from '../svgs/FormBgr';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MoreLink from '../MoreLink/MoreLink';
import WarningIcon from '../../images/icon-warning.svg';
import { validate } from 'email-validator';

const CONTENT_TYPES = {
  FORM: 'FORM',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const ContactForm = ({ id }) => {
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const step1EndRef = useRef();
  const showStep2 = Boolean(dirtyFields['topic']);

  useEffect(() => {
    if (step1EndRef?.current && showStep2) {
      step1EndRef.current.scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [showStep2]);

  const btnNextClickHandler = useCallback(
    async (event) => {
      event.preventDefault();
      const isValid = await trigger([
        'firstName',
        'lastName',
        'email',
        'phone',
        'company',
        'job',
      ]);
      setShowStep3(isValid);
    },
    [trigger],
  );

  const step2EndRef = useRef();
  const [showStep3, setShowStep3] = useState(false);

  useEffect(() => {
    if (step2EndRef?.current && showStep3) {
      step2EndRef.current.scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [showStep3]);

  const [showContent, setShowContent] = useState(CONTENT_TYPES.FORM);

  const step4EndRef = useRef();
  const [showStep4, setShowStep4] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (showStep4) {
      step4EndRef.current.scrollIntoView(false, {
        behavior: 'smooth',
      });
    }
  }, [showStep4]);

  const apiRoot = '';

  const onSubmit = useCallback(
    async (data) => {
      setHasSubmitted(true);
      try {
        if (!executeRecaptcha) {
          throw new Error();
        }

        const csrfTokenRequest = await fetch(
          `${apiRoot}/api/csrfToken/${new Date(
            new Date().toUTCString(),
          ).getTime()}`,
        );

        if (csrfTokenRequest.status !== 200 || !csrfTokenRequest.ok) {
          throw new Error();
        }

        const csrfToken = await csrfTokenRequest.json();

        if (csrfToken === undefined || csrfToken.token === undefined) {
          throw new Error();
        }

        const token = await executeRecaptcha('contactUs');
        const response = await fetch(`${apiRoot}/api/message`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, token, _csrf: csrfToken.token }),
        });
        if (response.status === 200 && response.ok) {
          setShowContent(CONTENT_TYPES.SUCCESS);
          setShowStep4(true);
          return;
        }
      } catch (error) {
        console.error(error);
      }

      setShowContent(CONTENT_TYPES.ERROR);
      setShowStep4(true);
    },
    [executeRecaptcha],
  );

  const [currentTopic, setCurrentTopic] = useState(null);
  const onTest = (a) => {
    setCurrentTopic(a.target.value);
  };

  return (
    <div className={styles.contactFormWrapper}>
      <ParallaxWithBgr background={<FormBgr className={styles.parallaxBgr} />}>
        {showContent === CONTENT_TYPES.FORM && (
          <form
            action="#"
            method="POST"
            className={styles.contactForm}
            onSubmit={handleSubmit(onSubmit)}>
            <BernyDialog>
              <div className={styles.formStepContainer}>
                <h2>Hello! What would you like to chat about?</h2>
                <div className={styles.controlGroup} onChange={onTest}>
                  <RadioButton
                    label="Working with us"
                    value="Working with us"
                    name="topic"
                    className={
                      currentTopic === 'Working with us'
                        ? styles.topicActive
                        : ''
                    }
                    {...register('topic', { required: true })}
                  />
                  <RadioButton
                    label="Careers"
                    value="Careers"
                    name="topic"
                    className={
                      currentTopic === 'Careers' ? styles.topicActive : ''
                    }
                    {...register('topic', { required: true })}
                  />
                  <RadioButton
                    label="Press enquiries"
                    value="Press enquiries"
                    name="topic"
                    className={
                      currentTopic === 'Press enquiries'
                        ? styles.topicActive
                        : ''
                    }
                    {...register('topic', { required: true })}
                  />
                  <RadioButton
                    label="Something else"
                    value="Something else"
                    name="topic"
                    className={
                      currentTopic === 'Something else'
                        ? styles.topicActive
                        : ''
                    }
                    {...register('topic', { required: true })}
                  />
                </div>
              </div>
              <div ref={step1EndRef} />
            </BernyDialog>

            <div
              className={cx(styles.formStep, {
                [styles.showStep]: showStep2,
              })}>
              <BernyDialog>
                <div className={styles.formStepContainer}>
                  <h2>Tell us about yourself...</h2>
                  <div className={styles.controlGroup}>
                    <TextField
                      className={styles.textField}
                      label="First name"
                      autoComplete="given-name"
                      error={errors?.firstName?.message}
                      {...register('firstName', {
                        required: 'Please answer this question',
                      })}
                    />
                    <TextField
                      className={styles.textField}
                      label="Last name"
                      autoComplete="family-name"
                      error={errors?.lastName?.message}
                      {...register('lastName', {
                        required: 'Please answer this question',
                      })}
                    />
                    <TextField
                      className={styles.textField}
                      label="Your email"
                      autoComplete="email"
                      error={errors?.email?.message}
                      {...register('email', {
                        required: 'Please enter your email address',
                        pattern: {
                          validate: (x) => validate(x),
                          message: 'Invalid email address',
                        },
                      })}
                    />
                    <TextField
                      className={styles.textField}
                      label="Your phone number (optional)"
                      autoComplete="tel"
                      error={errors?.phone?.message}
                      {...register('phone')}
                    />
                    <TextField
                      className={styles.textField}
                      label="Your company name (optional)"
                      autoComplete="organization"
                      error={errors?.company?.message}
                      {...register('company')}
                    />
                    <TextField
                      className={styles.textField}
                      label="Your job title (optional)"
                      autoComplete="organization-title"
                      error={errors?.job?.message}
                      {...register('job')}
                    />
                    <div>
                      {!showStep3 && (
                        <Button type="button" onMouseDown={btnNextClickHandler}>
                          Next
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div ref={step2EndRef} />
              </BernyDialog>
            </div>

            <div
              className={cx(styles.formStep, {
                [styles.showStep]: showStep3,
              })}>
              <BernyDialog>
                <div className={styles.formStepContainer}>
                  <h2>What can we help you with?</h2>
                  <p>Let us know what you’re looking for</p>
                  <div className={styles.controlGroup}>
                    <div>
                      <textarea
                        className={styles.message}
                        {...register('message', {
                          required: 'Please answer this question',
                        })}
                      />
                    </div>
                    <CheckBox
                      className={styles.checkBox}
                      label={
                        <>
                          Please this box to agree to the storage and handling
                          of your data for your request by this website in
                          accordance with our{' '}
                          <a
                            href={'/policies#:~:text=Privacy Policy'}
                            target="_blank">
                            Policies
                          </a>
                        </>
                      }
                      error={errors?.acceptedPolicy?.message}
                      {...register('acceptedPolicy', {
                        required: 'Please accept our policies',
                      })}
                    />
                    <div>
                      <Button type="submit" disabled={hasSubmitted}>
                        {!hasSubmitted
                          ? 'Send your message'
                          : "It's on the way!"}
                      </Button>
                    </div>
                    <div
                      style={{
                        display:
                          Object.keys(errors).length === 0 ? 'none' : 'flex',
                      }}
                      className={styles.errorSummary}>
                      <img
                        src={WarningIcon}
                        alt="Form warning"
                        loading="lazy"
                      />{' '}
                      <p>Please complete the fields marked in red</p>
                    </div>
                  </div>
                  <p>
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer">
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="https://policies.google.com/terms"
                      target="_blank"
                      rel="noopener noreferrer">
                      Terms of Service
                    </a>{' '}
                    apply.
                  </p>
                </div>
              </BernyDialog>
            </div>
          </form>
        )}
        {showContent === CONTENT_TYPES.SUCCESS && (
          <div className={styles.messageForm}>
            <BernyDialog>
              <div className={styles.formStepContainer}>
                <h2 className={styles.formStepTitle}>Message sent</h2>
                <p className={styles.formStepDescription}>
                  Thanks for getting in touch, we’ll get back to you shortly
                </p>
                <MoreLink
                  to="/"
                  isDarkSecondary={true}
                  className={styles.formStepButtonsItem}>
                  Back to homepage
                </MoreLink>
              </div>
            </BernyDialog>
            <div ref={step4EndRef} />
          </div>
        )}
        {showContent === CONTENT_TYPES.ERROR && (
          <div className={styles.messageForm}>
            <BernyDialog>
              <div className={styles.formStepContainer}>
                <h2 className={styles.formStepTitle}>
                  Oops <br /> Something went wrong
                </h2>
                <p className={styles.formStepDescription}>
                  Not quite sure what happened there, would you like to start
                  again?
                </p>
                <div className={styles.formStepButtons}>
                  <MoreLink
                    to="javascript:window.location.reload()" // Horrible hack
                    isDarkSecondary={true}
                    className={styles.formStepButtonsItem}>
                    Start the form again
                  </MoreLink>
                  <MoreLink
                    to="/"
                    isDarkSecondary={true}
                    className={styles.formStepButtonsItem}>
                    Back to homepage
                  </MoreLink>
                </div>
              </div>
            </BernyDialog>
            <div ref={step4EndRef} />
          </div>
        )}
      </ParallaxWithBgr>
    </div>
  );
};
export default ContactForm;
