import React, { memo } from 'react';
import cx from 'classnames';

const FormBgr = ({ className, ...rest }) => (
  <svg
    width="1440"
    height="2287"
    viewBox="0 0 1440 2287"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...rest}>
    <circle cx="45.5" cy="809.5" r="10" fill="#6feedb" stroke="#6feedb" />
    <path
      opacity="0.4"
      d="M1356.5 824L1395.91 846.75V892.25L1356.5 915L1317.1 892.25V846.75L1356.5 824Z"
      fill="#6feedb"
    />
    <path
      opacity="0.4"
      d="M1369.5 843L1408.91 865.75V911.25L1369.5 934L1330.1 911.25V865.75L1369.5 843Z"
      fill="#6feedb"
    />
    <path
      opacity="0.4"
      d="M40 2097L122.272 2144.5V2239.5L40 2287L-42.2724 2239.5V2144.5L40 2097Z"
      fill="#6feedb"
    />
    <path
      opacity="0.4"
      d="M40 2074L122.272 2121.5V2216.5L40 2264L-42.2724 2216.5V2121.5L40 2074Z"
      fill="#6feedb"
    />
    <path
      d="M272.112 790.074L299.334 688.482L400.927 661.26L475.298 735.631L448.076 837.224L346.483 864.445L272.112 790.074Z"
      stroke="#1E1E1E"
    />
    <path
      d="M1324.35 1358.61L1355.26 1243.26L1470.61 1212.35L1555.06 1296.8L1524.15 1412.15L1408.8 1443.06L1324.35 1358.61Z"
      stroke="#1E1E1E"
    />
    <path
      opacity="0.4"
      d="M1377 83.2836V25.8536L1423.93 1.12992L1471 25.8545V84.1433L1423.94 109.856L1377 83.2836Z"
      fill="#6feedb"
      stroke="#6feedb"
      strokeWidth="2"
    />
    <circle
      opacity="0.4"
      cx="1233"
      cy="1283"
      r="4.5"
      fill="#6feedb"
      stroke="#6feedb"
    />
    <circle
      opacity="0.4"
      cx="1357"
      cy="248"
      r="8.5"
      fill="#6feedb"
      stroke="#6feedb"
    />
  </svg>
);

export default memo(FormBgr);
